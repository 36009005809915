import type {
    CurrentMemberFragment,
    GuestUserFragment,
} from 'generated/graphql';
import { useAppData } from '@modules/root';
import type { User } from '~/server/types';

export const isUserLoggedIn = (user: User): user is CurrentMemberFragment => {
    return user.__typename === 'Me';
};

export const useUser = (): User => {
    return useAppData().user;
};

export const useUserLoggedIn = ():
    | {
          user: CurrentMemberFragment;
          isLoggedIn: true;
      }
    | {
          user: GuestUserFragment;
          isLoggedIn: false;
      } => {
    const user = useUser();
    if (isUserLoggedIn(user)) {
        return { user, isLoggedIn: true };
    }
    return { user, isLoggedIn: false };
};
